<template>
  <div>
    <h1 :class="$style.pageTitle">運行前点検 月別状況</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form class="no-print" layout="inline" style="margin-bottom: 20px;">
              対象支店：
              <a-select
                show-search
                v-model="branchId"
                style="width: 30%; margin-right: 20px;"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              対象月：
              <a-button @click="yesterday()" style="margin-right: 3px;">前月</a-button>
              <a-button @click="today()" style="margin-right: 3px;">今月</a-button>
              <a-button @click="tomorrow()" style="margin-right: 7px;">翌月</a-button>
              <a-select v-model="year" style="width: 80px;">
                <a-select-option
                  v-for="item in years"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              年

              <a-select v-model="month" style="width: 60px;">
                <a-select-option
                  v-for="item in months"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              月

              <a-button type="primary" @click="refreshList" style="margin-left: 10px;" :class="$style.filledBtn">取得</a-button>
              <span class="float-right">
                <router-link :to="{ path: '/inspection/unconfirmed' }">
                  <a-button type="primary" :class="$style.filledBtn">未確認一覧</a-button>
                </router-link>
              </span>
            </a-form>
            <hr class="mt-4">
          </div><!-- /.card-body (フィルタ) -->

          <div class="card-body">
            <div v-if="loading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-else>
              <h4><span class="font-weight-bold">{{ gotDate }} {{ gotBranchName }}</span> の運行前点検状況</h4>
              <a-table
                :columns="columns"
                :data-source="summaryDataList"
                :pagination="false"
                :bordered="true"
                rowKey="id"
                :rowClassName="
                  (r, i) => i % 2 === 0 && r.manual === 1 ? $style.manual_row + ' ' + $style.stripe : i % 2 === 0 ? $style.stripe : r.manual === 1 ? $style.manual_row : ''
                "
                :scroll="{x: true}"
                class="mt-4"
                :class="$style.aTable"
              >
                <span slot="vehcleName" slot-scope="name, record"><router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ record.name }}</router-link></span>
                <span v-for="item in inspectionItems" :key="item.id" :slot="item.id" slot-scope="id, record">
                  {{ record.inspections['item' + item.id].last_date ? '' : '-' }} {{ record.inspections['item' + item.id].status === 0 ? 'NG: ' : '' }} {{ record.inspections['item' + item.id].last_date | momentDate }}
                </span>
              </a-table>
            </div>
          </div><!-- /.card-body (一覧) -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      year: moment().format('YYYY'),
      month: moment().format('MM'),

      gotDate: '', // 一覧データを取得した日 (YYYY/MM/DD)
      gotBranchName: '', // 一覧データを取得した支店名
      loading: true,

      summaryDataList: [], // サマリーリスト

      branchId: null, // 選択された支店ID
      branchIdSelected: null, // 選択された支店ID (確定)
      branches: [], // 対象支店リスト
      vehicles: [], // 対象車両リスト
      inspectionItems: [], // 点検項目リスト
    }
  },
  computed: {
    years() {
      const thisYear = Number(moment().format('YYYY'))
      const rtn = []
      // 機能の作成タイミング的に 2023年以前にはデータはない。
      for (let i = 0; thisYear - 2023 - i >= 0; i++) {
        rtn.push(thisYear - i)
      }
      return rtn
    },
    targetMonth() {
      return moment(this.year + '-' + this.month + '-01')
    },
    targetMonthHyphen() {
      return this.targetMonth.format('YYYY-MM-DD')
    },
    // 一覧ヘッダー
    columns() {
      const c = [
        {
          title: '支店名',
          dataIndex: 'branch_name',
          scopedSlots: { customRender: 'branch_name' },
          sorter: false,
          width: 120,
        },
        {
          title: '車両名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'vehcleName' },
          width: 150,
          customCell: (text, row, index) => {
            if (text.status === 0) {
              return {
                class: 'wrongCell',
              }
            }
          },
          sorter: (a, b) => a.status - b.status,
          defaultSortOrder: 'ascend',
        },
      ]
      this.inspectionItemsGroupByCategory.forEach(category => {
        const block = []
        category.items.forEach(i => {
          block.push({
            title: i.name,
            scopedSlots: { customRender: i.id },
            width: 120,
            customCell: (text, row, index) => {
              if (!Object.keys(text.inspections['item' + i.id]).length || text.inspections['item' + i.id].status === 0) {
                return {
                  class: 'wrongCell',
                }
              }
            },
          })
        })
        c.push({
          title: category.name,
          class: 'border-left-thick',
          children: block,
        })
      })
      return c.filter(e => e != null)
    },
    // カテゴリでグルーピングした点検項目
    inspectionItemsGroupByCategory() {
      const groups = []
      for (const item of this.inspectionItems) {
        const categoryName = item.category || ''
        let group = groups.find(g => g.name === categoryName)
        if (!group) {
          group = {
            name: categoryName,
            items: [],
          }
          groups.push(group)
        }
        group.items.push(item)
      }
      return groups
    },
  },
  created() {
    if (this.$route.query.date) {
      const m = moment(this.$route.query.date)
      this.year = m.format('YYYY')
      this.month = m.format('MM')
    }

    Vue.prototype.$api.send('get', 'user/branches')
      .then(response => {
        // 先頭に「全社」を追加
        this.branches = [{ id: 0, name: '全社' }].concat(response)
        this.branchId = Number(this.$route.query.branchId) || this.branches[0].id
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'inspection_items')
      .then(response => {
        this.inspectionItems = response

        Vue.prototype.$api.send('get', `vehicles/list/0/open`)
          .then(responseV => {
            this.vehicles = responseV.map((item) => {
              const inspectionObj = {}
              response.forEach(i => {
                inspectionObj['item' + i.id] = {}
              })
              item.inspections = inspectionObj
              if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
              return item
            })
            this.refreshList()
          })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': 車両の取得に失敗しました。',
            })
          })
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 運行前点検項目の取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'user')
      .then(myResponse => {
        this.concatVehicleNameAndNo = myResponse.concat_vehicle_name_and_no
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })
  },
  filters: {
    momentDateTime: function (datetime) {
      return datetime ? moment(datetime).format('YYYY/MM/DD HH:mm:ss') : ''
    },
    momentDate: function (date) {
      if (date) return moment(date).format('MM/DD')
      return ''
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    yesterday() {
      this.year = moment(this.targetMonth).subtract(1, 'month').format('YYYY')
      this.month = moment(this.targetMonth).subtract(1, 'month').format('MM')
    },
    today() {
      this.year = moment().format('YYYY')
      this.month = moment().format('MM')
    },
    tomorrow() {
      this.year = moment(this.targetMonth).add(1, 'month').format('YYYY')
      this.month = moment(this.targetMonth).add(1, 'month').format('MM')
    },
    extractVehicles() {
      if (this.branchId === 0) {
        this.summaryDataList = this.vehicles
      } else {
        this.summaryDataList = this.vehicles.filter(v => v.branch_id === this.branchId)
      }
      this.summaryDataList = this.summaryDataList.map((item) => {
        const inspectionObj = {}
        this.inspectionItems.forEach(i => {
          inspectionObj['item' + i.id] = {}
        })
        item.inspections = inspectionObj
        item.status = 0
        return item
      })
    },
    // 一覧データ取得
    refreshList() {
      this.loading = true
      this.branchIdSelected = this.branchId
      this.extractVehicles()
      Vue.prototype.$api.send(
        'get',
        'inspections/summary',
        {
          date: this.targetMonthHyphen,
          branch_id: this.branchId,
        },
      )
        .then(response => {
          response.map((item) => {
            const i = this.summaryDataList.findIndex(s => s.id === item.vehicle_id)
            if (i !== -1) {
              this.summaryDataList[i].inspections['item' + item.inspection_item_id] = {
                status: item.status,
                last_date: item.last_date,
              }
            }
            return item
          })
          for (const i in this.summaryDataList) {
            let flag = 1
            for (const j in this.summaryDataList[i].inspections) {
              if (!Object.keys(this.summaryDataList[i].inspections[j]).length || this.summaryDataList[i].inspections[j].status === 0) {
                flag = 0
                break
              }
            }
            this.summaryDataList[i]['status'] = flag
          }
        })
        // .catch(error => {
        //   this.$notification['error']({
        //     message: error.status + ': 運行前点検の取得に失敗しました。',
        //   })
        // })
        .finally(() => {
          this.gotDate = this.targetMonth.format('YYYY年MM月')
          this.gotBranchName = this.branches.find(e => e.id === this.branchId).name
          this.loading = false
        })
    },
  },
}
</script>

<style>
.ant-table-bordered .ant-table-thead > tr > th, body .ant-table-wrapper tr td {
  border: 1px solid #e8e8e8 !important;
}
.ant-table-bordered .ant-table-thead > tr > .border-left-thick, .ant-table-bordered .ant-table-tbody > tr > .border-left-thick {
  border-left: 2.5px solid #e8e8e8 !important;
}
.wrongCell {
  background-color: #FED1D1 !important;
}
</style>
